import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Auctions from './components/Auctions';

class Home extends Component {
  render() {
    return (
      <>
        <div id='header-bg'>
            <div className="container">
              <Header />
            </div>
        </div>
        <div className='container'>
          <Auctions />
        </div>
        <Footer/>
      </>
    );
  }
}

export default Home;