import React from 'react'

function Footer() {
  return (
    <footer>
        <div className="container">
            <div className="text-center">
                <p className="copy-right text-light">MarsRise LLC © 2022 All Rights Reserved</p>
            </div>
        </div>
    </footer>
  )
}

export default Footer