const Address = {
  staking: {
    4: "0x005D1A37EF2715053F0387BCA3F5CE4324DA9cc9",
    1: "0xA14DDeadF2d682193Ea71CC1A4a8Be5CA24AC4C4",
    56: "0x9190F256b4F7E2e9C231DA5fCBCf5B3b4395e51A",
  },
  ape: {
    4: "0x2baed587c73b6359bd3d1d96003ab9f49f461abd",
    1: "0xd35b4d6b6f5807c552d7fdb79c7fab1d7a232c3a",
    56: "0x2a075bfb43a3eab718715f5c9b40d8d70fe30d31",
  },
  variable: {
    4: "0xc19d613b1221322eb735054f53ddbfa6a564df9c",
    1: "0xb24040ea13e14f3f844ae12e033f94a843300b86",
    56: "0xb24040ea13e14f3f844ae12e033f94a843300b86",
  },
};

export default Address;
