import addresses from "../config/token";

// const chainId = 4;
// const chainId = 1;
const chainId = 56;
export const getStakingAddress = () => {
  return addresses.staking[chainId];
};

export const getVariableAddress = () => {
  return addresses.variable[chainId];
};

export const getApeAddress = () => {
  return addresses.ape[chainId];
};
